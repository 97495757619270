import { query } from '@confluence/query-preloader-tools';

import { RecentContentMenuQuery } from './RecentContentMenuQuery.graphql';

export function preloadRecentContentMenu(cloudId) {
	return query({
		query: RecentContentMenuQuery,
		variables: { cloudId },
		fetchPolicy: 'network-only',
	});
}
