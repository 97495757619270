import gql from 'graphql-tag';

import { ContentMenuFragment } from './ContentMenuFragment.fragment';

export const RecentContentMenuQuery = gql`
	query RecentContentMenuQuery($cloudId: ID!) {
		activities {
			myActivities {
				all(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								objectTypes: [PAGE, BLOGPOST, WHITEBOARD, DATABASE, EMBED]
								cloudIds: [$cloudId]
							}
						}
					]
					first: 12
				) {
					nodes {
						id
						timestamp
						object {
							id
							events {
								eventType
							}
							content {
								...ContentMenuFragment
							}
						}
					}
				}
			}
		}
	}
	${ContentMenuFragment}
`;
